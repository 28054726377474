<template>
  <div class="cntDettaglio" v-if="!loading">
    <v-btn class="indietro" fab small color="white" @click="$router.go(-1)">
      <v-icon>mdi-arrow-left</v-icon>
    </v-btn>
    <Carousel class="carousel custom-carousel" :value="quadri" :numVisible="1" :numScroll="1" autoplayInterval="3000">
      <template #item="slotProps">
        <div @click="imageViewerFlag = true" style="cursor: pointer;">
          <img :src="slotProps.data" style="width: 100%;" />
        </div>
      </template>
    </Carousel>
    <div class="infoQuadro">
      <h1 v-html="item.nome"></h1>
      <div>
        <p>{{ item.dimensioni }}</p>
        <p style="font-weight: bold">{{ prezzo }}</p>
      </div>
      <p v-html="item.descrizione"></p>
      <v-btn color="blue-grey" class="ma-2 white--text btnAggiungi" @click="aggiungiACarrello" :disabled="isQuadroInCarrello || disablePerPrezzoNonDisponibile">
        {{ !isQuadroInCarrello ? "Aggiungi nel carrello" : "Quadro già aggiunto nel carrello" }}
        <v-icon right dark>
          mdi-cart-minus
        </v-icon>
      </v-btn>
    </div>
    <image-viewer-vue class="imageViewer" v-if="imageViewerFlag && !isPhone" @closeImageViewer="imageViewerFlag = false" :imgUrlList="quadri" :index="indexImg" :closable="true" :title="''" />
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import catalogoApi from "../api/catalogoApi";
import utils from "../utils/utils.js";
import Carousel from "primevue/carousel";
export default {
  props: ["id", "isPhone"],
  components: {
    Carousel
  },
  data() {
    return {
      quadri: [],
      item: null,
      imageViewerFlag: false,
      indexImg: 0
    };
  },

  async created() {
    this.setLoading(true);
    this.item = await catalogoApi.getItemById(this.id);
    var keys = Object.keys(this.item);
    for (var i = 0; i < keys.length; i++) {
      if (keys[i].includes("foto") && this.item[keys[i]]) {
        this.quadri.push(this.item[keys[i]]);
      }
    }
    this.setLoading(false);
  },

  computed: {
    ...mapGetters({
      loading: "getLoading",
      carrello: "getCarrello"
    }),

    prezzo() {
      return this.item.prezzo ? utils.convertCurrency("it-IT", "EUR", this.item.prezzo) : "Prezzo non disponibile";
    },

    isQuadroInCarrello() {
      var item = this.carrello.find(el => el._id == this.item._id);
      if (!item) return false;
      else return true;
    },

    disablePerPrezzoNonDisponibile() {
      return this.item && !this.item.prezzo;
    }
  },

  methods: {
    ...mapMutations(["setItemInCarrello", "setLoading"]),
    aggiungiACarrello() {
      this.setItemInCarrello(this.item);
    }
  }
};
</script>

<style lang="scss">
.cntDettaglio {
  margin: 100px;
  display: flex;
  .indietro {
    position: absolute;
    left: 10px;
  }
  .carousel {
    width: 50%;
    .p-carousel-item {
      display: flex;
      align-items: center;
    }
    .p-carousel-indicators {
      display: none;
    }
  }
  .infoQuadro {
    width: 100%;
    margin-left: 15px;
    .btnAggiungi {
      margin: 1rem 0 0 0 !important;
      float: right;
    }
    .btnAggiungi:disabled {
      color: white !important;
      .v-icon {
        color: white !important;
      }
    }
  }
  .imageViewer {
    background-color: rgba(55,55,55,.9) !important;
    .iconfont {
      color: black;
    }
  }
}

.phone {
  .cntDettaglio {
    margin: 80px 15px;
    flex-direction: column;
    .indietro {
      position: unset;
    }
    .carousel {
      width: unset !important;
      margin-top: 25px;
    }
    .infoQuadro {
      width: unset !important;
      margin-left: unset !important;
      margin-top: 2rem;
    }
  }
}
</style>
